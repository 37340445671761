import {useEffect, useRef, useState, useCallback} from "react";
import useWebSocket, {ReadyState} from 'react-use-websocket';
import msgpack from 'msgpack-lite';

import {
    AppBar,
    Container,
    Button,
    Stack,
    Box,
    Toolbar,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";

import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';

const scheme = document.location.protocol === "https:" ? "wss" : "ws";
const hostname = document.location.hostname;
const port = document.location.port ? ":" + document.location.port : "";
const defaultUrl = `${scheme}://${hostname}${port}/live`
// const defaultUrl = "wss://localhost:7296/bist"

const apiKey = "70abdcfe-88b2-49cb-a83e-fe34716e7e47";

const supportedProtocols = {
    ddecompat: "Legacy compat format from DDE",
    json: "Json format",
};

const subscriptionTypes = {
    all: "Subscribe all symbols",
    selected: "Subscribe selected symbols",
};

const isBinaryProtocol = protocol => {
    return protocol === 'msgpack'
}

const uniqueId = () => parseInt(Date.now() * Math.random()).toString();

function App() {
    const [connect, setConnect] = useState(false);

    const [socketUrl, setSocketUrl] = useState(defaultUrl);
    const [protocol, setProtocol] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState(null);
    const [wsOptions, setWsOptions] = useState({
        reconnectAttempts: 10,
        reconnectInterval: 1000
    });
    const {
        sendMessage: doSendMessage,
        lastMessage,
        readyState,
        getWebSocket
    } = useWebSocket(socketUrl, wsOptions, connect);

    const [rows, setRows] = useState([]);

    const subscribeLastText = useRef();
    const subscribeBarText = useRef();

    useEffect(() => {
        if (readyState !== ReadyState.OPEN) return;
        if (!isBinaryProtocol(protocol)) return;

        getWebSocket().binaryType = 'arraybuffer'
    }, [protocol, readyState, getWebSocket]);

    useEffect(() => {
        if (!lastMessage || !lastMessage.data) return;
        // console.log(lastMessage)
        let symbolUpdates = null

        switch (protocol) {
            case 'json': {
                const data = JSON.parse(lastMessage.data)
                if (data.L) {
                    symbolUpdates = [data.L]
                } else if (data.B) {
                    symbolUpdates = [data.B]
                }
            }
                break
            case 'ddecompat':
            default:
                symbolUpdates = `${lastMessage.data}`.split(';')
                    .map(symbolUpdateStr => {
                        const parts = symbolUpdateStr.substring(3).split('|')
                        const symbolName = parts[0]
                        const lastPrice = parts[1]

                        return {
                            S: symbolName,
                            P: lastPrice
                        }
                    })
                break
        }

        if (!symbolUpdates) return;
        console.table(symbolUpdates)

        setRows((prev) => [...symbolUpdates, ...prev.slice(0, 25)])
    }, [lastMessage, setRows]);

    const onConnectHandler = useCallback(() => {
        setConnect(false);
        const wsOptionsExtra = {
            'queryParams': {
                'x-api-key': apiKey
            }
        }
        if (subscriptionType) {
            wsOptionsExtra.queryParams['subscription-mode'] = subscriptionType
        }
        if (protocol && protocol !== '') {
            wsOptionsExtra['protocols'] = protocol
        }
        setWsOptions((prev) => ({
            ...prev,
            ...wsOptionsExtra
        }))
        setConnect(true);
    }, [protocol, subscriptionType, setWsOptions, setConnect]);

    const sendMessage = (method, params) => {
        switch (protocol) {
            case 'json':
                const payload = {}
                payload[method] = params
                doSendMessage(JSON.stringify(payload))
                break
            case 'msgpack':
                const data = {
                    subscribe: [],
                    unsubscribe: []
                }
                data[method] = params
                doSendMessage(msgpack.encode(data))
                console.warn(`protocol ${protocol} not implemented`)
                break
            case 'ddecompat':
            default:
                doSendMessage(method + ':' + params.join(','))
                break
        }
    }

    const onCloseSocketHandler = () => {
        setConnect(false);
    }

    const supportedProtocolsElements = Object.entries(supportedProtocols)
        .map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
    const subscriptionTypesElements = Object.entries(subscriptionTypes)
        .map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)

    const subscribeToLast = (value) => {
        console.log('subscribeToLast ', value)
        const symbols = value.split(',')

        sendMessage('s:p', symbols)
    }

    const unSubscribeFromLast = (value) => {
        console.log('unSubscribeFromLast ', value)
        const symbols = value.split(',')

        sendMessage('u:p', symbols)
    }

    const subscribeToBar = (value) => {
        console.log('subscribeToBar ', value)
        const symbols = value.split(',')

        sendMessage('s:b', symbols)
    }

    const unSubscribeFromBar = (value) => {
        console.log('unSubscribeFromBar ', value)
        const symbols = value.split(',')

        sendMessage('u:b', symbols)
    }

    return (
      <>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Box alignItems="stretch" justifyContent="flex-start">
                Zephlex Streamer Test Application
              </Box>
            </Typography>

            {/*<InputLabel id="demo-select-small-label" htmlFor="protocol">Connection Protocol:</InputLabel>*/}
            <FormControl>
              <InputLabel htmlFor="subscription-select">
                Subscription type
              </InputLabel>
              <Select
                required
                id="subscription-select"
                size="small"
                variant="outlined"
                label="Protocol"
                defaultValue=""
                disabled={readyState === ReadyState.OPEN}
                onChange={(event) => setSubscriptionType(event.target.value)}
              >
                <MenuItem value="">
                  <em>Select subscription</em>
                </MenuItem>
                {subscriptionTypesElements}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel htmlFor="protocol-select">Protocol</InputLabel>
              <Select
                required
                id="protocol-select"
                size="small"
                color="primary"
                variant="outlined"
                label="Protocol"
                defaultValue=""
                disabled={readyState === ReadyState.OPEN}
                onChange={(event) => setProtocol(event.target.value)}
              >
                <MenuItem value="">
                  <em>Select protocol</em>
                </MenuItem>
                {supportedProtocolsElements}
              </Select>
            </FormControl>

            <Stack
              spacing={2}
              direction="column"
              aria-orientation={"horizontal"}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Stack spacing={1} direction="row">
                <TextField
                  required
                  label="Server Websocket Endpoint"
                  size="small"
                  color="primary"
                  variant="outlined"
                  defaultValue={socketUrl}
                  onChange={(event) => setSocketUrl(event.target.value)}
                  disabled={readyState === ReadyState.OPEN}
                />
                {readyState === ReadyState.OPEN && (
                  <CloudOutlinedIcon color="success" fontSize="large" />
                )}
                {readyState !== ReadyState.OPEN && (
                  <CloudOffOutlinedIcon color="error" fontSize="large" />
                )}
                <Button
                  color="inherit"
                  onClick={onConnectHandler}
                  disabled={
                    readyState === ReadyState.OPEN || socketUrl.length === 0
                  }
                >
                  Connect
                </Button>
                <Button
                  color="inherit"
                  onClick={onCloseSocketHandler}
                  disabled={readyState !== ReadyState.OPEN}
                >
                  Disconnect
                </Button>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
        <Container style={{ width: "100%" }}>
          <Stack
            spacing={2}
            direction="row"
            aria-orientation={"vertical"}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Stack spacing={1} direction="row">
              <TextField
                inputRef={subscribeLastText}
                id="standard-basic"
                label="LastPrice"
                size="small"
                color="primary"
                variant="outlined"
                disabled={readyState !== ReadyState.OPEN}
              />
              <Button
                color="inherit"
                disabled={readyState !== ReadyState.OPEN}
                onClick={() => subscribeToLast(subscribeLastText.current.value)}
              >
                SUB
              </Button>
              <Button
                color="inherit"
                onClick={() => unSubscribeFromLast(subscribeLastText.current.value)}
                disabled={readyState !== ReadyState.OPEN}
              >
                UNSUB
              </Button>
            </Stack>
            <Stack spacing={1} direction="row">
              <TextField
                inputRef={subscribeBarText}
                id="standard-basic"
                label="BarData"
                size="small"
                color="primary"
                variant="outlined"
                disabled={readyState !== ReadyState.OPEN}
              />
              <Button
                color="inherit"
                disabled={readyState !== ReadyState.OPEN}
                onClick={() => subscribeToBar(subscribeBarText.current.value)}
              >
                SUB
              </Button>
              <Button
                color="inherit"
                onClick={() => unSubscribeFromBar(subscribeBarText.current.value)}
                disabled={readyState !== ReadyState.OPEN}
              >
                UNSUB
              </Button>
            </Stack>
          </Stack>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                border: 0,
                fontFamily: "monospace",
                fontSize: "12px",
              }}
              size="small"
              aria-label="symbol updates"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">DateTime</TableCell>
                  <TableCell align="center">LastPrice</TableCell>
                  <TableCell align="center">Open</TableCell>
                  <TableCell align="center">High</TableCell>
                  <TableCell align="center">Low</TableCell>
                  <TableCell align="center">Close</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Volume</TableCell>
                  <TableCell align="center">WeightedAverage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={`${row.S}-${uniqueId()}`}>
                    <TableCell component="th" scope="row">
                      {row.S}
                    </TableCell>
                    <TableCell align="right">{row.D}</TableCell>
                    <TableCell align="right">{row.P}</TableCell>
                    <TableCell align="right">{row.O}</TableCell>
                    <TableCell align="right">{row.H}</TableCell>
                    <TableCell align="right">{row.L}</TableCell>
                    <TableCell align="right">{row.C}</TableCell>
                    <TableCell align="right">{row.Q}</TableCell>
                    <TableCell align="right">{row.V}</TableCell>
                    <TableCell align="right">{row.W}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </>
    );
}

export default App;
